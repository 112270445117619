<template>
  <a-card :bordered="false" class="assessmentTaskItemPage">
    <div class="header float">
      <h4 class="one_line">
        {{ itemData.missionName || "暂无" }}
      </h4>
      <div class="tag padding" v-if="getItemStatus(itemData) == 1">进行中</div>
      <div class="tag ending" v-if="getItemStatus(itemData) == 2">已完成</div>
      <div class="tag over" v-if="getItemStatus(itemData) == 3">已过期</div>
    </div>
    <div class="info all">
      <p>发布时间：{{ itemData.publishTime || "暂无" }}</p>
      <p>截止时间：{{ itemData.endTime || "暂无" }}</p>
    </div>
    <div class="detail" :class="learnController ? '' : 'one_line'">
      {{ itemData.missionDesc }}
    </div>
    <button
      class="detailController left"
      v-show="itemData.missionDesc && itemData.missionDesc.length > 53"
      @click="learnController = !learnController"
    >
      {{ learnController ? "收起" : "展开更多" }} <i class="bg"></i>
    </button>
    <div class="list all">
      <div class="item" v-for="(itm, idx) in itemData.caseResponseList" :key="idx">
        <div class="title more_line">
          {{ itm.caseNumber }}-{{ itm.caseName }}
        </div>
        <div class="detail float">
          <div class="info">
            <p>分值：{{ itm.score || 0 }}</p>
          </div>
          <button
            class="tag padding"
            v-if="getItemStatus(itemData) == 1 && !itm.hasFinish"
            @click="handleClick(itm.caseId, itemData.missionId)"
          >
            开始考核
          </button>
          <button class="tag ending" :class="itm.hasFinish" v-if="itm.hasFinish">已完成</button>
          <button
            class="tag over"
            :class="!itm.hasFinish && itemData.state == 3"
           v-if="getItemStatus(itemData) == 3 && !itm.hasFinish"
          >
            未参与练习
          </button>
        </div>
      </div>
    </div>
  </a-card>
</template>
<script>
export default {
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      learnController: false,
    };
  },
  methods: {
    /**
     * 1 进行中
     * 2 已完成
     * 3 已过期
     */
    getItemStatus({caseResponseList=[],endTime=""}){
      let isFinish = caseResponseList.every(item=>item.hasFinish);
      if(isFinish)return 2;
      if(new Date().getTime() < new Date(endTime).getTime())return 1;
      return 3;
      
    },
    async handleClick(caseId, missionId) {
      if (!missionId || !caseId) {
        this.$notification.error({
          message: "警告",
          description: `该基础练习未配置完整,请联系管理员`,
          duration: null,
        });
        return;
      }
      try {
        let res = await this.$http.get(this.$interface.examCheckStartCount);
        this.$router.push(`/practice?caseId=${caseId}&missionId=${missionId}`);
      } catch (err) {}
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>